<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-card-account-details</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Accounts</v-toolbar-title>
      <v-spacer />
      <SearchField hint="Account oder Person" v-model="search"></SearchField>
    </v-app-bar>
    <v-toolbar flat class="mb-4">
      <v-btn-toggle dense v-model="filter">
        <v-btn text v-for="f in filters" :key="f.value" :value="f">
          <v-icon v-if="f.icon" left>{{ f.icon }}</v-icon
          >{{ f.label }}
        </v-btn>
      </v-btn-toggle>
      <LookupValueInput
        single-line
        hide-details
        v-model="filterStatus"
        :items="stati"
        class="ml-4"
        :loading="loading"
        clearable
      />
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="itemsFiltered"
        :item-class="() => 'clickable'"
        @click:row="(item) => showDetails(item.id)"
      >
        <template v-slot:item.person="{ item }">
          <PersonItem small :value="item.person" />
        </template>
        <template v-slot:item.status="{ item }">
          <LookupValue :value="item.status" />
        </template>
        <template v-slot:item.statusChangedOn="{ item }">
          <DateValue :value="item.statusChangedOn" />
        </template>
        <template v-slot:item.statusOnHold="{ item }">
          <v-simple-checkbox
            v-model="item.statusOnHold"
            disabled
          ></v-simple-checkbox>
        </template>
      </v-data-table>
    </v-card>
    <router-view @dataChanged="fetchData"></router-view>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
import { comparePeople, searchPerson } from "common/utils/people.js";

import DateValue from "common/components/DateValue.vue";
import LookupValue from "common/components/LookupValue.vue";
import LookupValueInput from "common/components/LookupValueInput.vue";
import PersonItem from "common/components/PersonItem";
import SearchField from "@/components/SearchField";

export default defineComponent({
  name: "Accounts",
  components: {
    DateValue,
    LookupValue,
    LookupValueInput,
    PersonItem,
    SearchField,
  },
  data() {
    return {
      loading: false,
      filter: null,
      filters: [
        {
          label: "onHold",
          filter: (item) => item.statusOnHold,
        },
      ],
      filterStatus: null,
      search: "",
      stati: [],
      headers: [
        { text: "Name", value: "samAccountName" },
        { text: "Person", value: "person", sort: comparePeople },
        { text: "Status", value: "status" },
        { text: "Status changed", value: "statusChangedOn" },
        { text: "onHold", value: "statusOnHold" },
      ],
      items: [],
    };
  },
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (item) =>
          (!this.filter || this.filter.filter(item)) &&
          (!this.filterStatus ||
            (item.status && item.status.id == this.filterStatus.id)) &&
          (this.search == null ||
            item.samAccountName
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            searchPerson(item.person, this.search))
      );
    },
  },
  watch: {},
  methods: {
    async fetchData() {
      this.loading = true;
      this.items = await this.apiList({
        resource: "account/account",
      });
      this.stati = await this.apiList({
        resource: "account/status",
      });
      this.loading = false;
    },
    showDetails(id) {
      this.$router.push({
        name: "AccountDetails",
        params: { id: id },
      });
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
